import styles from './casp-vasp-list.module.scss';
import { ReactComponent as ExternalLinkIcon } from '../../assets/images/svg/external-link.svg';
import useLanguage from '../../hooks/language';
import Header from '../../components/header/Header';
import routes from '../../routes/routes';


const CaspVaspList = () => {
  const { language } = useLanguage();
  
  return <div>
    <Header headerTitle='Knowledge hub / List of CASP and VASP' withBackButton backRoute={routes.home.knowledgeHub.dashboard} />

    <div className={styles.externalReferenceContainer}>
      {language === 'sk' && <div className={styles.textContainer}>
        <span>Zoznam CASP/VASP je dostupný v</span>{' '}
        <a className={styles.linkContainer} target='_blank'
          href='https://blockmate-my.sharepoint.com/:x:/g/personal/peter_laubert_blockmate_io/EaANdgogRyJAsN6SXsX3twUB8b_T-Lqt6-5ctWqOR4V-PA?rtime=vGeay9o23Ug' rel='noreferrer'>
          tabuľke <div className={styles.icon}><ExternalLinkIcon/></div>
        </a>.
      </div>}
      {language === 'cs' && <div className={styles.textContainer}>
        <span>Seznam CASP/VASP je dostupný v</span>{' '}
        <a className={styles.linkContainer} target='_blank'
          href='https://blockmate-my.sharepoint.com/:x:/g/personal/peter_laubert_blockmate_io/EaANdgogRyJAsN6SXsX3twUB8b_T-Lqt6-5ctWqOR4V-PA?rtime=vGeay9o23Ug' rel='noreferrer'>
          tabulce <div className={styles.icon}><ExternalLinkIcon/></div>
        </a>.
      </div>}
    </div>

  </div>;
};

export default CaspVaspList;
